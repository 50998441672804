var bW = document.documentElement.clientWidth;
var bH = document.documentElement.clientHeight;
var scrollTop = $(window).scrollTop();;
$(window).on('resize', function () {
  bW = document.documentElement.clientWidth;
  bH = document.documentElement.clientHeight;
})

//img.retina要素のsrcsetを記述
$("img.retina").each(function (i, e) {
  var srcName = $(this).attr('src');
  if (srcName.indexOf('.svg') == -1) { //SVG以外のimg要素を選ぶ
    var srcSetTxt = srcName + " 1x," + srcName.replace(/(\.gif|\.jpg|\.png)/g, '@2x$1') + " 2x";
    srcName = $(this).attr('srcset', srcSetTxt);
  }
});

//ham Menu
(function () {
  $('#js-buttonHamburger').click(function () {
    $('.c-nav-sp').toggleClass('is-active');
    $('body').toggleClass('is-active');

    if ($(this).attr('aria-expanded') == 'false') {
      $(this).attr('aria-expanded', true);
    } else {
      $(this).attr('aria-expanded', false);
    }
  });
}) ();

//smooth scroll
$(function () {
  $('a[href^="#"]').click(function () {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
});

// top kv slider
if ($(".p-top-kv__slider")) {
  var topSlider = new Swiper('.p-top-kv__slider__inner', {
    speed: 1500,
    autoplay: {
      delay: 5000,
    },
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    effect: 'fade',
  })
}

$('body').addClass('show')